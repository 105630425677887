.portfolioContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.portfolio {
  width: 60%;
  margin-top: 5%;
  margin-bottom: 5%;
}
