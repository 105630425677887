.loader {
  background: --var(--background);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  z-index: 12;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.chargement {
  color: var(--primary-color);
}
