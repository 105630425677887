.pagesError {
  color: var(--primary-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10%;
}

.Button {
  background: transparent;
  color: var(--primary-color);
  border: 2px solid var(--secondary-color);
  margin-top: 2%;
  padding: 10px 40px;
  width: auto;
  text-transform: uppercase;
  outline: none;
  position: relative;
  overflow: hidden;
  z-index: 0;
  transition: all 0.3s;
}

.Button::before {
  content: "";
  position: absolute;
  background-color: var(--secondary-color);
  height: 100%;
  top: 0;
  left: 0;
  width: 0%;
  z-index: -1;
  transition: all 0.3s;
}

.Button:hover {
  color: var(--primary-color);
}

.Button:hover::before {
  width: 100%;
}
