@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");

:root {
  --background: #010813;
  --primary-color: white;
  --secondary-color: #f3bd1d;
  --thirteen-color: #212121;
  --fourthy-color: #989898;
  --fivethy-color: #212121;
}

* {
  background: var(--background);
  padding: 0;
  font-family: montserrat;
}
