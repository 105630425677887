.Navbar {
  margin-top: 0;
  width: 100%;
}

.Menu {
  padding-right: 3%;
}

.ContenerProjet {
  display: flex;
  justify-content: center;
}

.PageProjects {
  margin-top: 6%;
  width: 80%;
  height: 450px;
  display: flex;
  align-items: center;
  color: var(--primary-color);
  background: var(--fivethy-color);
}

.LeftPart {
  margin-left: 2%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background: transparent;
}

.Description {
  background: transparent;
  font-size: 16px;
  color: grey;
  line-height: 2em;
}

.LeftPart h2 {
  background: transparent;
}

.LogicielLiens {
  display: flex;
  justify-content: space-around;
  background: transparent;
}

.LogicielsPart {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background: transparent;
}

.Logiciels {
  background: var(--fivethy-color);
}

.Logiciel {
  height: 40px;
  background: var(--fivethy-color);
  padding-left: 3px;
}

.RightPart {
  width: 100%;
  display: flex;
  justify-content: center;
  background: transparent;
}

.ImgProjet {
  width: 90%;
  height: auto;
}

.Container_One_Project {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Container_button {
  margin-top: 10px;
  gap: 0%;
  width: 50%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.Button {
  background: transparent;
  color: var(--primary-color);
  border: 2px solid var(--secondary-color);
  margin-top: 2%;
  padding: 10px 40px;
  width: auto;
  text-transform: uppercase;
  outline: none;
  position: relative;
  overflow: hidden;
  z-index: 0;
  transition: all 0.3s;
}

.Button::before {
  content: "";
  position: absolute;
  background-color: var(--secondary-color);
  height: 100%;
  top: 0;
  left: 0;
  width: 0%;
  z-index: -1;
  transition: all 0.3s;
}

.Button:hover {
  color: var(--primary-color);
}

.Button:hover::before {
  width: 100%;
}

@media screen and (max-width: 600px) {
  .PageProjects {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    height: 80vh;
  }
  .Container_button {
    width: 80%;
  }
  .Description {
    font-size: 12px;
  }
}
